@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
}

body {
  margin: 0;
  font-family: -apple-system, 'Mazzard', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Mazzard';
  src: url('./assets/fonts/mazzard/MazzardH-Regular.otf') format('opentype');
  font-style: normal; /* Adjust as necessary: normal, italic, etc. */
}

@font-face {
  font-family: 'MazzardBold';
  src: url('./assets/fonts/mazzard/MazzardH-Bold.otf') format('opentype');
  font-style: normal; /* Adjust as necessary: normal, italic, etc. */
}

@font-face {
  font-family: 'MazzardExtraThin';
  src: url('./assets/fonts/mazzard/MazzardH-ExtraLight.otf') format('opentype');
  font-style: normal; /* Adjust as necessary: normal, italic, etc. */
}

/* Make the container scrollable */
.sticky-container {
  @apply relative h-screen w-full overflow-y-scroll snap-y snap-mandatory;
}

/* Sections with sticky behavior */
.sticky-section {
  @apply relative h-screen w-full flex items-center justify-center snap-start;
}

.snap-container {
  @apply h-screen w-full overflow-y-scroll snap-y snap-mandatory;
}

/* Snap sections */
.snap-section {
  @apply snap-start;
}
