@keyframes spinone {
    from {
        transform: translate(65%, -50%) rotate(0deg);
    }
    to {
        transform: translate(65%, -50%) rotate(360deg);
    }
}
@keyframes spintwo {
    from {
        transform:translate(50%, -50%) rotate(0deg);
    }
    to {
        transform:translate(50%, -50%) rotate(360deg);
    }
}

.spinwheelone {
    animation: spinone 35s linear infinite;
}

.spinwheeltwo {
    animation: spintwo 45s linear infinite;
}
